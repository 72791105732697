import React from 'react';

import { Routes, Route, Outlet } from 'react-router-dom'

const ProtectionRoute = () => {
	return <Outlet />
}
const MobileApp = () => {
	return <Routes>
		<Route path="/" element={<ProtectionRoute />}>
			<Route path="/" element={<h1>Hello, World !</h1>} />
			<Route path="*" element={<h1>404 Not Found</h1>} />
		</Route>
	</Routes>
}

export default MobileApp;
